var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline',_vm._b({ref:"timeline",class:[
    'cy-event-timeline pt-0',
    { 'cy-event-timeline--stacked': _vm.stacked } ],attrs:{"align-top":"","dense":_vm.stacked}},'v-timeline',_vm.$attrs,false),[_c('v-data-iterator',_vm._b({ref:"iterator",class:_vm.iteratorClass,attrs:{"items":_vm.events,"hide-default-footer":""},on:{"update:options":function (options) { return _vm.$emit('update:options', options); },"current-items":function (items) { return _vm.currentlyVisibleEvents = items; }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_c('v-lazy',{class:[
          'cy-event-timeline-lazy-item',
          {
            'cy-event-timeline-lazy-item--first-in-range': _vm.isItemFirstInRelativeTimeRange(item, index),
          } ],attrs:{"options":_vm.lazyOptions,"min-height":"51"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('CyEventsTimelineItem',{ref:"item",class:{
              'flex-wrap-reverse': _vm.stacked,
            },attrs:{"event":item,"force-show-opposite":_vm.shouldForceShowOpposite(item, index),"show-relative-timestamp":_vm.shouldShowRelativeTimestamp(item, index, hover),"show-absolute-timestamp":_vm.shouldShowAbsoluteTimestamp(hover)},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
            var tag = ref.tag;
return [_vm._t("tag",function(){return [_c('CyTag',{ref:"tag",attrs:{"variant":tag.variant,"is-selected":_vm.isTagSelected(tag),"label":tag.key,"element-type":"button","small":""},on:{"click":function($event){return _vm.$emit('tag-click', tag)}}},[_vm._v(" "+_vm._s(tag.content)+" ")])]},null,{ tag: tag })]}}],null,true)})]}}],null,true)})],1)]}},{key:"footer",fn:function(){return [_vm._t("pagination")]},proxy:true}],null,true)},'v-data-iterator',{ options: _vm.options, loading: _vm.loading },false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }