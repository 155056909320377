<template>
  <v-timeline-item
    right
    small
    :icon="getEventsIcon(event)"
    :color="null"
    :class="[
      'cy-event-timeline-item pb-0',
      { 'cy-event-timeline-item--force-show-opposite': forceShowOpposite },
    ]">
    <template #opposite>
      <div
        v-show="showRelativeTimestamp"
        :class="[
          'cy-event-timestamp cy-event-timestamp--relative',
          { 'line-height-md': forceShowOpposite },
        ]"
        data-cy="timestamp-relative">
        {{ formatTimeAgo(event.timestamp) }}
      </div>
      <div
        v-show="showAbsoluteTimestamp"
        class="cy-event-timestamp cy-event-timestamp--absolute"
        data-cy="timestamp-absolute">
        <div>
          {{ formatTimestamp(event.timestamp).day }}
        </div>
        <div>
          {{ formatTimestamp(event.timestamp).time }}
        </div>
      </div>
    </template>
    <div
      v-if="event.type === 'Cycloid'"
      data-cy="message"
      v-html="renderEventMessage(event)"/>
    <template v-else>
      <div
        class="font-weight-bold pa-0"
        data-cy="title">
        {{ event.title }}
      </div>
      <v-expansion-panels class="cy-event-details mt-2">
        <v-expansion-panel class="elevation-1 rounded">
          <v-expansion-panel-header class="cy-event-details__header px-4 py-0">
            <template #default="{ open }">
              <div
                :class="[{
                  'text-truncate': !open,
                  'py-3': open,
                }]"
                data-cy="heading"
                v-html="renderEventMessage(event, 'heading')"/>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="cy-event-details__content">
            <div
              data-cy="body"
              v-html="renderEventMessage(event, 'body')"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
    <v-card-text class="pa-0 mt-2">
      <CyTagList
        ref="tags"
        :tags="getEventTags(event)"
        contained
        small
        sort-key="">
        <template #tag="{ tag }">
          <slot
            name="tag"
            v-bind="{ tag }"/>
        </template>
      </CyTagList>
    </v-card-text>
  </v-timeline-item>
</template>

<script>
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import CyTagList from '@/components/tag-list'

export const icons = {
  abort: 'cancel',
  create: 'add',
  delete: 'delete',
  disable: 'power_settings_new',
  enable: 'power_settings_new',
  pause: 'pause',
  pin: 'push_pin',
  refresh: 'refresh',
  rerun: 'repeat_one',
  unpause: 'play_arrow',
  unpin: 'push_pin',
  update: 'edit',
  other: 'info',
}

export default {
  name: 'CyEventsTimelineItem',
  components: {
    CyTagList,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    forceShowOpposite: {
      type: Boolean,
      default: false,
    },
    showRelativeTimestamp: {
      type: Boolean,
      default: false,
    },
    showAbsoluteTimestamp: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    $static: () => ({
      severityColor: {
        err: 'error',
        crit: 'error',
        warn: 'warning',
        info: 'secondary',
      },
    }),
  },
  methods: {
    formatTimeAgo (timestamp) {
      return this.$date.$formatTimeAgo(timestamp)
    },
    formatTimestamp (timestamp) {
      const day = moment(timestamp).format('MMMM D, YYYY') // eslint-disable-line you-dont-need-momentjs/format
      const time = moment(timestamp).format('h:mm A') // eslint-disable-line you-dont-need-momentjs/format
      return { day, time }
    },
    getEventsIcon (event) {
      const eventType = _.get(_.find(event.tags, { key: 'action' }), 'value', 'other')
      return icons[eventType]
    },
    getEventTags ({ severity, type, tags }) {
      const sortedTags = _.sortBy(tags, ['key'])
      return _.transform(sortedTags, (result, tag) => {
        result.push({
          ...tag,
          variant: 'default',
          content: tag.value,
        })
      }, [
        {
          key: 'severity',
          value: severity,
          variant: this.$static.severityColor[severity],
          content: this.$t(`severities.${severity}`),
        },
        {
          key: 'type',
          value: type,
          variant: 'secondary',
          content: this.$t(`events.type.${type}`),
        },
      ])
    },
    renderEventMessage (event, part) {
      const isDeleteEvent = !!_.find(event.tags, { key: 'action', value: 'delete' })
      const sanitizedMessage = this.$sanitizeHtml(event.message)

      if (isDeleteEvent) {
        const parsedMessage = new DOMParser().parseFromString(sanitizedMessage, 'text/html')
        const deletedEntityLinkEl = parsedMessage.querySelectorAll('a')[1]
        const deletedEntityStaticEl = document.createElement('strong')
        deletedEntityStaticEl.textContent = deletedEntityLinkEl.textContent
        deletedEntityLinkEl.parentNode.replaceChild(deletedEntityStaticEl, deletedEntityLinkEl)
        return parsedMessage.body.innerHTML
      }

      if (part) {
        const htmlLineBreakAtStartOrEndRegex = /(?:^<br>+)|(?:<br>+$)/g
        const lineBreakRegex = /\r|<\/br>|<br\/>|\n/g
        const messageWithUniformLineBreaks = sanitizedMessage.replace(lineBreakRegex, '<br>')
        const messageHeadingAndBody = messageWithUniformLineBreaks.split(/<br>(.+)/)
        const trim = (string = '') => string.replace(htmlLineBreakAtStartOrEndRegex, '').trim()
        return {
          heading: trim(messageHeadingAndBody[0]),
          body: trim(messageHeadingAndBody[1]),
        }[part] || trim(messageWithUniformLineBreaks)
      }

      return sanitizedMessage
    },
  },
}
</script>

<style lang="scss" scoped>
.v-timeline-item {
  color: get-color("primary");
  font-size: map.get($font-sizes, "base");

  ::v-deep &__opposite {
    align-self: start;
  }

  ::v-deep &__dot {
    transform: translate3d(0, 3px, 0);
  }

  ::v-deep &__inner-dot {
    outline: 4px solid map.get($grey, "light-4");
    background-color: get-color("grey", "light-3") !important;

    .v-icon {
      color: get-color("grey", "dark-3") !important;
    }
  }
}

// Custom elements
.cy-event-timeline-item--force-show-opposite {
  ::v-deep .v-timeline-item__opposite {
    display: initial;
    z-index: 1;
    background-color: get-color("white");
  }
}

.cy-event-timestamp {
  color: get-color("grey", "dark-2");
  font-size: 14px;
}

.cy-event-details {
  .v-expansion-panel {
    background-color: get-color("grey", "light-4");
    color: inherit;
  }

  .v-expansion-panel-header {
    min-height: 40px;
    font-size: inherit;
  }

  ::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    color: get-color("grey", "dark-1");
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding-right: 16px;
    padding-left: 16px;
  }
}

::v-deep .tag-list__item:first-child {
  margin-left: 0;
}
</style>
